import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  deleteAddress,
  GET_CONTACT_ADDRESSES,
  getContactAddresses,
  saveAddress,
  setMailingAddress,
} from '^/address/actions';
import AddressList from '^/address/address-list';
import AddressForm from '^/address/form';
import { Address, AddressLabels } from '^/address/types';
import AppButton from '^/common/app-button';
import { Loading } from '^/common/loading';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

export enum AddressesMode {
  Create = 'CREATE',
  List = 'LIST',
  Edit = 'EDIT',
}

interface OwnProps {
  contactId: string;
  defaultLabel?: AddressLabels;
  disabled?: boolean;
  allowAdd?: boolean;
}

interface StateProps {
  mode?: AddressesMode;
  editingId?: string;
}

export type AddressesProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class Addresses extends React.PureComponent<AddressesProps> {
  public componentDidMount() {
    const { contactAddresses, contactId } = this.props;

    if (paginatedArrayHasExpired(contactAddresses[contactId])) {
      this.props.getContactAddresses(contactId);
    }
  }

  public render() {
    const {
      contactAddresses,
      mode,
      editingId,
      isLoading,
      contactId,
      defaultLabel,
      disabled,
      allowAdd = true,
    } = this.props;

    const addresses = getAllRetrievedPages(contactAddresses[contactId]);

    if (isLoading && addresses.length <= 0) {
      return <Loading />;
    }

    switch (mode) {
      case AddressesMode.Create:
      case AddressesMode.Edit:
        const editingAddress = addresses.find(
          address => address.id === editingId
        );

        return (
          <div className="flex-column">
            <AddressForm
              onSubmit={this.onSaveAddress}
              initialValues={
                mode === AddressesMode.Edit
                  ? editingAddress
                  : { label: defaultLabel, country: 'United Kingdom' }
              }
              onCancel={this.onClickCancelAddAddress}
            />
          </div>
        );
      case AddressesMode.List:
      default:
        return (
          <div className="flex-row">
            <AddressList
              addresses={addresses}
              editAddress={this.onClickEditAddress}
              deleteAddress={this.onClickDeleteAddress}
              setMailingAddress={this.onClickSetMailingAddress}
              disabled={disabled}
            />
            {allowAdd && (
              <div className="page-subsection-buttons">
                <AppButton
                  onClick={this.onClickAddAddress}
                  small
                  disabled={disabled}
                >
                  Add
                </AppButton>
              </div>
            )}
          </div>
        );
    }
  }

  public onClickAddAddress = () => {
    this.props.setProps({
      mode: AddressesMode.Create,
      editingId: undefined,
    });
  };

  public onClickCancelAddAddress = () => {
    this.props.setProps({
      mode: AddressesMode.List,
      editingId: undefined,
    });
  };

  public onClickEditAddress = (addressId: string) => {
    this.props.setProps({
      mode: AddressesMode.Edit,
      editingId: addressId,
    });
  };

  public onClickDeleteAddress = (addressId: string) => {
    this.props.deleteAddress(addressId, this.props.contactId);
  };

  public onClickSetMailingAddress = (addressId: string) => {
    this.props.setMailingAddress(this.props.contactId, addressId);
  };

  public onSaveAddress = (address: Address) => {
    const addressWithID = { ...address, contact: this.props.contactId };
    return this.props
      .saveAddress(
        this.props.editingId ? this.props.editingId : null,
        addressWithID
      )
      .then(() =>
        this.props.setProps({ mode: AddressesMode.List, editingId: undefined })
      );
  };
}

export { Addresses as TestableAddresses };

export const getInitialProps = () => ({
  mode: AddressesMode.List,
  editingId: undefined,
});

export const mapState = (store: StoreState) => ({
  contactAddresses: store.contactAddresses,
  isLoading: anyPending(store.responses, [GET_CONTACT_ADDRESSES]),
});

const connector = connect(mapState, {
  deleteAddress,
  saveAddress,
  setMailingAddress,
  getContactAddresses,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(Addresses)
);
