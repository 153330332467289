import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { addressActions } from '^/address/actions';
import Addresses from '^/address/addresses-component';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { GET_CONTACT, SAVE_CONTACT } from '^/contacts/actions';
import PaymentDetailsSection from '^/contacts/payment-details-section';
import PersonsDetailsCard from '^/contacts/persons/details-card';
import { paymentDetailActions } from '^/payment-details/actions';
import { subscriptionActions } from '^/plans/subscriptions/actions';
import PlanSubscriptionsTable from '^/plans/subscriptions/table';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsDetailCard from '^/relationships/detail-card';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export type PatientDetailsProps = ConnectedProps<typeof connector> & {
  contactId: string;
};

class PatientDetails extends React.PureComponent<PatientDetailsProps> {
  public render() {
    const { individual, contactId, loading } = this.props;

    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <PageSection>
            <PageSubSection heading="Contact details" loading={loading.details}>
              <PersonsDetailsCard personId={contactId} />
            </PageSubSection>
            <PageSubSection heading="Addresses" loading={loading.addresses}>
              <Addresses contactId={contactId} />
            </PageSubSection>
            <RelationshipsDetailCard
              heading="Clinician"
              contactId={contactId}
              linkContactName={false}
              label="Clinician"
              types={[RelationshipType.PatientPerformer]}
              hideRecents
              filters={{
                relationship: RelationshipType.PerformerPractice,
                category: 'DEN',
              }}
            />
            <PaymentDetailsSection
              allowAdd
              hidePayees
              linkContactName={false}
              contact={individual}
            />
          </PageSection>
          <PageSection heading="Plans">
            <PlanSubscriptionsTable contactId={contactId} />
          </PageSection>
        </Column>
      </Row>
    );
  }
}

export { PatientDetails as TestablePatientDetails };

export const mapState = (state: StoreState, props: { contactId: string }) => ({
  individual: getItemFromCache(props.contactId, state.contactsCache),
  loading: {
    addresses: anyPending(state.responses, addressActions),
    details: anyPending(state.responses, [GET_CONTACT, SAVE_CONTACT]),
    memberships: anyPending(state.responses, subscriptionActions),
    paymentDetails: anyPending(state.responses, paymentDetailActions),
    relationships: anyPending(state.responses, relationshipActions),
  },
});

const connector = connect(mapState, {});

export default connector(PatientDetails);
