import React from 'react';

import { AddressResponse } from '^/address/types';
import AddressListItem from './address-list-item';

export interface AddressListProps {
  addresses: ReadonlyArray<AddressResponse>;
  disabled?: boolean;
  editAddress(addressId?: string): void;
  deleteAddress(addressId?: string): void;
  setMailingAddress(addressId?: string): void;
}

export class AddressList extends React.PureComponent<AddressListProps> {
  public render() {
    const { addresses, disabled } = this.props;

    if (addresses.length <= 0) {
      return <div className="empty-state">No Addresses</div>;
    }

    return (
      <div className="address-list">
        {addresses.map(address => (
          <AddressListItem
            key={address.postcode}
            address={address}
            data-id={address.id}
            onEdit={this.props.editAddress.bind(this, address.id)}
            onDelete={this.props.deleteAddress.bind(this, address.id)}
            onSetMailing={this.props.setMailingAddress.bind(this, address.id)}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }
}

export default AddressList;
