import { anyPending } from '@dabapps/redux-requests';
import { Column, ModalBody, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import RelatedActivities from '^/activities/related-activities';
import {
  DELETE_ADDRESS,
  GET_CONTACT_ADDRESSES,
  SAVE_ADDRESS,
  SET_MAILING_ADDRESS,
} from '^/address/actions';
import Addresses from '^/address/addresses-component';
import { UserRole } from '^/admin/users/types';
import Collections from '^/collections/collections';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import AppButtonGroup from '^/common/app-button-group';
import ConfirmationSidebarCard from '^/common/confirmation-sidebar-card';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import Sidebar from '^/common/sidebar';
import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';
import { GET_CONTACT, SAVE_CONTACT } from '^/contacts/actions';
import CompaniesDetailsCard from '^/contacts/companies/details-card';
import {
  LAPSE_PRACTICE_SUBSCRIPTIONS,
  lapsePracticeSubscriptions,
} from '^/contacts/companies/practices/actions';
import PaymentDetailsSection from '^/contacts/payment-details-section';
import { ContactStatus } from '^/contacts/types';
import InspracCard from '^/insurance/insprac-card';
import SubscriptionsMembershipAddButton from '^/memberships/subscriptions/add-button';
import MembershipSubscriptionsTable from '^/memberships/subscriptions/table';
import { closeModal, openModal } from '^/modals/actions';
import SimpleModal from '^/modals/simple-modal';
import { paymentDetailActions } from '^/payment-details/actions';
import PlanAddButton from '^/plans/add-button';
import PlansTable from '^/plans/table';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsDetailCard from '^/relationships/detail-card';
import RelationshipsListCard from '^/relationships/list-card';
import PracticePerformersCard from '^/relationships/practice-performers';
import { RelationshipType } from '^/relationships/types';
import { ContactType, StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import ImportPatientsModal from './modals/import-patients-modal';

export type PracticeDetailsTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

export class PracticeDetailsTab extends React.PureComponent<
  PracticeDetailsTabProps
> {
  constructor(props: PracticeDetailsTabProps) {
    super(props);
    this.handleLapsePracticeSubscriptions = this.handleLapsePracticeSubscriptions.bind(
      this
    );
    this.openConfirmLapseSubscriptionModal = this.openConfirmLapseSubscriptionModal.bind(
      this
    );
    this.openImportPatientsModal = this.openImportPatientsModal.bind(this);
  }

  public render() {
    const {
      contact,
      match: {
        params: { contactId },
      },
      loading,
    } = this.props;

    const disabled = contact?.status === ContactStatus.Archived;

    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <PageSection>
            <PageSubSection
              heading="Practice Details"
              loading={loading.details}
            >
              <CompaniesDetailsCard
                labelPrefix="Practice"
                companyId={contactId}
                disabled={disabled}
              />
            </PageSubSection>
            <RelationshipsListCard
              heading="Practice Owner"
              contactId={contactId}
              filters={{ type: 'Individual,Company,Practice' }}
              label="Owner"
              showBothSides
              types={[RelationshipType.CompanyProvider]}
              detailed
              createInReverse
              disabled={disabled}
            />
            <RelationshipsDetailCard
              heading="Practice Contact"
              contactId={contactId}
              filters={{
                type: [ContactType.Individual, ContactType.Company].join(','),
              }}
              label="Contact"
              types={[RelationshipType.CompanyContact]}
              disabled={disabled}
            />
            <PageSubSection heading="Addresses" loading={loading.addresses}>
              <Addresses contactId={contactId} disabled={disabled} />
            </PageSubSection>
            <PracticePerformersCard
              practiceId={contactId}
              disabled={disabled}
            />
            <RelationshipsListCard
              heading="Practice Team Members"
              contactId={contactId}
              filters={{ type: 'Individual' }}
              label="Team Members"
              showCategories
              types={[RelationshipType.TeamMemberCompany]}
              disabled={disabled}
            />
            <RelationshipsListCard
              heading="Linked Practices"
              contactId={contactId}
              types={[RelationshipType.LinkedPractice]}
              label="Linked Practice"
              showBothSides
              filters={{ type: 'Practice,Individual,Company' }}
              disabled={disabled}
              paginated
            />
            <PaymentDetailsSection
              contact={contact}
              disabled={disabled}
              hidePayees
            />
            <PageSubSection heading="INSPRAC">
              <InspracCard contactId={contactId} disabled={disabled} />
            </PageSubSection>
          </PageSection>
          <PageSection heading="Memberships">
            <MembershipSubscriptionsTable contactId={contactId} />
            <div className="page-section-buttons">
              <SubscriptionsMembershipAddButton
                contactId={contactId}
                disabled={disabled}
                isCompany
              />
            </div>
          </PageSection>
          <PageSection heading="Plans">
            <PlansTable contactId={contactId} />
            <div className="page-section-buttons">
              <PlanAddButton contactId={contactId} disabled={disabled} />
            </div>
          </PageSection>
          <Collections contactId={contactId} disabled={disabled} />
        </Column>
        <Sidebar>
          <RelatedActivities contactId={contactId} disabled={disabled} />
          {contactId && (
            <Allow roles={[UserRole.AdminLevel]}>
              <SidebarCard heading="Import Patients">
                <SidebarCardSection>
                  <div className="buttons">
                    <AppButton onClick={this.openImportPatientsModal}>
                      Import patients from CSV
                    </AppButton>
                  </div>
                </SidebarCardSection>
              </SidebarCard>
              <ConfirmationSidebarCard
                heading="Lapse All Subscriptions"
                confirmationText="This action involves lapsing all patient subscriptions, deleting pending collections and all patient payment details. This cannot be undone."
                actionLabel="Lapse All Subscriptions"
                title="Lapse All Subscriptions"
                onConfirm={this.openConfirmLapseSubscriptionModal}
                actions={[LAPSE_PRACTICE_SUBSCRIPTIONS]}
              />
            </Allow>
          )}
        </Sidebar>
      </Row>
    );
  }

  public openConfirmLapseSubscriptionModal() {
    this.props.openModal(
      <SimpleModal
        heading="Lapse All Subscriptions"
        onClose={this.props.closeModal}
      >
        <ModalBody>
          <div className="modal-body-section">
            <p>
              This action involves lapsing all patient subscriptions, deleting
              pending collections and all patient payment details. This cannot
              be undone.
            </p>
            <p>
              This will take place over the next couple minutes if actioned.
            </p>
          </div>
          <div className="modal-body-section form-buttons">
            <AppButtonGroup>
              <AppButton onClick={this.props.closeModal}>Cancel</AppButton>
              <AppButton
                onClick={this.handleLapsePracticeSubscriptions}
                destructive
              >
                Lapse All Subscriptions
              </AppButton>
            </AppButtonGroup>
          </div>
        </ModalBody>
      </SimpleModal>
    );
  }

  public openImportPatientsModal() {
    const contactId = this.props.match.params.contactId;
    if (contactId) {
      this.props.openModal(<ImportPatientsModal practiceId={contactId} />);
    }
  }

  public handleLapsePracticeSubscriptions() {
    this.props
      .lapsePracticeSubscriptions(this.props.match.params.contactId)
      .then(() => this.props.closeModal());
  }
}

export const mapState = (
  store: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  contact: getItemFromCache(props.match.params.contactId, store.contactsCache),
  loading: {
    details: anyPending(store.responses, [GET_CONTACT, SAVE_CONTACT]),
    addresses: anyPending(store.responses, [
      GET_CONTACT_ADDRESSES,
      SAVE_ADDRESS,
      DELETE_ADDRESS,
      SET_MAILING_ADDRESS,
    ]),
    relationships: anyPending(store.responses, relationshipActions),
    paymentDetails: anyPending(store.responses, paymentDetailActions),
  },
});

const connector = connect(mapState, {
  lapsePracticeSubscriptions,
  openModal,
  closeModal,
});

export default connector(PracticeDetailsTab);
