import React from 'react';

import AppButton from '^/common/app-button';
import AppButtonGroup from '^/common/app-button-group';
import ConfirmButton from '^/common/confirm-button';
import { formatAddress } from './helpers';
import { Address, AddressResponse } from './types';

export interface AddressListItemProps {
  address: AddressResponse;
  disabled?: boolean;
  onEdit(): void;
  onDelete(): void;
  onSetMailing(address: AddressResponse): void;
}

class AddressListItem extends React.PureComponent<AddressListItemProps> {
  public render() {
    const { address, disabled } = this.props;
    const isMailingAddress = address.id === address.contact_mailing_address;
    return (
      <div className="address-list-item">
        <div className="address-list-item-header">
          <label>Address type:</label> {address.label}
        </div>
        {isMailingAddress && (
          <div className="address-list-item-header">
            <label>Correspondence type:</label> Mailing
          </div>
        )}
        <div className="address-line">{formatAddress(address)}</div>
        {this.renderAddressContacts(address)}
        <AppButtonGroup>
          <AppButton link onClick={this.props.onEdit} disabled={disabled}>
            Edit
          </AppButton>
          <ConfirmButton onConfirm={this.props.onDelete} disabled={disabled}>
            Remove
          </ConfirmButton>
          {!isMailingAddress && (
            <AppButton
              link
              onClick={this.handleClickSetMailing}
              disabled={disabled}
            >
              Set mailing
            </AppButton>
          )}
        </AppButtonGroup>
      </div>
    );
  }

  public handleClickSetMailing = () => {
    this.props.onSetMailing(this.props.address);
  };

  public renderAddressContacts = (address: Address) => {
    const numbers = [
      address.phone_1,
      address.phone_2,
      address.fax ? `${address.fax} (fax)` : undefined,
    ].filter(Boolean);

    if (numbers.length < 1) {
      return null;
    }

    return (
      <div className="address-line address-line-numbers">
        {numbers.join(' • ')}
      </div>
    );
  };
}

export { AddressListItem as TestableAddressListItem };

export default AddressListItem;
