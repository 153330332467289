import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { CONTACTS_ENDPOINT } from '^/iplan/actions';
import { rethrowOnAnyError } from '^/utils/action-helpers';
import { NewPatientFormValues } from './types';

export const CLINICIANS_ENDPOINT = '/api/iplan/clinicians';

export const GET_PRACTICE_CLINICIANS = makeAsyncActionSet(
  'GET_PRACTICE_CLINICIANS'
);

export function getPracticeClinicians(contactId: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_PRACTICE_CLINICIANS,
      `${CLINICIANS_ENDPOINT}/?practice=${contactId}`,
      'GET'
    )(dispatch);
}

export const PLANS_ENDPOINT = '/api/plans';

export const GET_PRACTICE_PLANS = makeAsyncActionSet('GET_PRACTICE_PLANS');

export function getPracticePlans(contactId: string) {
  return (dispatch: Dispatch) => {
    const url = `${PLANS_ENDPOINT}/?page=1&page_size=10&practice=${contactId}&show_inspat_if_has_sub=false&archived=true&variable_pricing=false&filter_available_for_signup=true`;
    return request(GET_PRACTICE_PLANS, url, 'GET')(dispatch);
  };
}

export const CREATE_NEW_PATIENT = makeAsyncActionSet('CREATE_NEW_PATIENT');
export function createNewPatient(data: Partial<NewPatientFormValues>) {
  return (dispatch: Dispatch) => {
    return request(
      CREATE_NEW_PATIENT,
      `${CONTACTS_ENDPOINT}/patients/create/`,
      'POST',
      data,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch).catch(errors => {
      throw new SubmissionError(errors.response.data);
    });
  };
}
